import React, { useEffect, useMemo, useState } from 'react';
import { ReservationService } from '../../services';
import { MainContent } from '../../components/MainContent';
import { IConcertDto, IConcertSeriesDto, IOrderDataDto, OrderDataDto } from '../../clients/services';
import {
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    Switch,
    TextField,
    Grid,
    SelectChangeEvent,
    LinearProgress,
    Checkbox,
    InputAdornment,
    Button,
    Card,
    Typography,
} from '@mui/material';
import { OrderService } from '../../services/order.service';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { actionContainerStyle } from '../Tickets/styles';
import { useAuth } from '../../auth/auth-provider';
import { OptionsService } from '../../services/price-calculator';
import { OrderInfo } from '../Tickets/parts/OrderInfo';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

const service = new OrderService();
const reservationService = new ReservationService();

const columns = [
    { field: 'id', headerName: 'Nr', width: 60 },
    {
        field: 'dateCreated',
        headerName: 'Datum',
        width: 90,
        renderCell: (params: { row: IOrderDataDto }) => params.row.dateCreated.toFormat('dd.MM.yyyy'),
    },
    {
        field: 'firstName',
        headerName: 'Vorname',
        width: 150,
        valueGetter: (params: { row: IOrderDataDto }) => params.row.person.firstName,
    },
    {
        field: 'lastName',
        headerName: 'Nachname',
        width: 150,
        valueGetter: (params: { row: IOrderDataDto }) => params.row.person.lastName,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
        valueGetter: (params: { row: IOrderDataDto }) => params.row.person.email,
        renderCell: (params: { row: IOrderDataDto; value: string }) => (
            <a href={`mailto:${params.value}`} title="Mail">
                {params.value}
            </a>
        ),
    },
    {
        field: 'count',
        headerName: 'Anz.',
        width: 60,
        valueGetter: (params: { row: IOrderDataDto }) => {
            return params.row.reservations.length;
        },
    },
    {
        field: 'price',
        headerName: 'Betrag',
        width: 120,
        valueGetter: (params: { row: IOrderDataDto }) => {
            const priceCalculator = new OptionsService(params.row.concert);
            return priceCalculator.getFullPrice(params.row.reservations);
        },
        renderCell: (params: { value: number }) => {
            return `CHF ${params.value.toFixed(2)}`;
        },
    },
    {
        field: 'payed',
        headerName: 'Bezahlt',
        width: 80,
        type: 'boolean',
        editable: false,
    },
    {
        field: 'canceled',
        headerName: 'Storniert',
        width: 80,
        type: 'boolean',
        editable: false,
    },
] as GridColumns<IOrderDataDto>;

export const Orders: React.FC = () => {
    const [hasError, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [concerts, setConcerts] = useState<IConcertDto[]>([]);
    const [concertSeries, setConcertSeries] = useState<IConcertSeriesDto[]>([]);
    const [concertSeriesId, setConcertSeriesId] = useState<number>(0);
    const [concertId, setConcertId] = useState<number>(0);
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const [showAllOrdersWithPayed, setShowAllOrdersWithPayed] = useState<boolean>(true);
    const [orders, setOrders] = useState<IOrderDataDto[]>([]);
    const [search, setSearch] = useState<string>('');
    const [selectedOrder, setSelectedOrder] = React.useState<IOrderDataDto | null>(null);

    const { profile, hasRole } = useAuth();

    const filteredOrders = useMemo(() => {
        const parts = search?.split(/(\s+)/) ?? [];
        return orders.filter(
            (o) =>
                (showAllOrdersWithPayed || !o.payed) &&
                (parts.length === 0 ||
                    parts.filter((p) => p === o.id.toString()).length > 0 ||
                    parts.filter((p) => o.person.firstName?.toLowerCase().startsWith(p.toLowerCase())).length > 0 ||
                    parts.filter((p) => o.person.lastName?.toLowerCase().startsWith(p.toLowerCase())).length > 0),
        );
    }, [showAllOrdersWithPayed, search, orders]);

    const loadConcerts = async (id: number) => {
        setLoading(true);
        try {
            const concerts = await reservationService.getConcertsForSeries(id);
            setConcerts(concerts);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadConcertSeries = async () => {
        setLoading(true);
        try {
            const series = await reservationService.getConcertSeries();
            const orderedSeries = series.sort((s1, s2) =>
                !!s1.seriesStart && !!s2.seriesStart && s1.seriesStart > s2.seriesStart ? -1 : 1,
            );
            setConcertSeries(orderedSeries);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const changePayed = async (order: IOrderDataDto) => {
        const newOrder = new OrderDataDto({
            ...order,
            payed: !order.payed,
        });
        setSelectedOrder(newOrder);
        setOrders(orders.map((o) => (o.id === order.id ? newOrder : o)));
        await service.setPayedAsync(order.id, !order.payed);
        if (concertId) {
            await loadOrdersForConcert(concertSeriesId, concertId);
        } else {
            await loadOrdersForSeries(concertSeriesId);
        }
    };

    const changeCanceled = async (order: IOrderDataDto) => {
        const newOrder = new OrderDataDto({
            ...order,
            canceled: !order.canceled,
        });
        setSelectedOrder(newOrder);
        setOrders(orders.map((o) => (o.id === order.id ? newOrder : o)));
        await service.setCanceledAsync(order.id, !order.canceled);
        if (concertId) {
            await loadOrdersForConcert(concertSeriesId, concertId);
        } else {
            await loadOrdersForSeries(concertSeriesId);
        }
    };

    const loadOrdersForSeries = async (concertSeriesId: number) => {
        setLoading(true);
        if (!concertSeriesId) {
            setOrders([]);
            return;
        }
        try {
            const seriesOrders = await service.getOrdersForSeriesAsync(concertSeriesId);
            setOrders(seriesOrders);
            if (selectedOrder) {
                const refreshedOrder = seriesOrders?.find((o) => o.id === selectedOrder?.id) ?? null;
                if (refreshedOrder) {
                    setSelectedOrder(refreshedOrder);
                    return;
                }
            }
            setSelectedOrder(seriesOrders[0] ?? null);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadOrdersForConcert = async (concertSeriesId: number, concertId: number) => {
        setLoading(true);
        if (!concertId) {
            loadOrdersForSeries(concertSeriesId);
            return;
        }
        try {
            const concertOrders = await service.getOrdersForConcertAsync(concertId);
            setOrders(concertOrders.orders);
            if (selectedOrder) {
                const refreshedOrder = concertOrders.orders?.find((o) => o.id === selectedOrder?.id) ?? null;
                if (refreshedOrder) {
                    setSelectedOrder(refreshedOrder);
                    return;
                }
            }
            setSelectedOrder(concertOrders.orders[0] ?? null);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const onEnter = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'enter') {
            const result = await service.searchAsync(search, concertSeriesId, concertId);
            setOrders(result);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const hasRoleAdmin = hasRole(profile, 'admin');
            setIsAllowed(hasRoleAdmin);
            if (hasRoleAdmin) {
                await loadConcerts(concertSeriesId);
                await loadConcertSeries();
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const hasRoleAdmin = hasRole(profile, 'admin');
            setIsAllowed(hasRoleAdmin);
            if (hasRoleAdmin) {
                await loadConcerts(concertSeriesId);
                await loadOrdersForConcert(concertSeriesId, concertId);
            }
        };
        loadData();
    }, [concertId]);

    useEffect(() => {
        const loadData = async () => {
            const hasRoleAdmin = hasRole(profile, 'admin');
            setIsAllowed(hasRoleAdmin);
            if (hasRoleAdmin) {
                await loadConcerts(concertSeriesId);
                await loadOrdersForSeries(concertSeriesId);
            }
        };
        loadData();
    }, [concertSeriesId]);

    const changeConcert = (event: SelectChangeEvent<number>) => {
        const concertId = parseInt(event.target.value as string);
        setConcertId(concertId);
    };

    const changeConcertSeries = (event: SelectChangeEvent<number>) => {
        const newValue = parseInt(event.target.value as string);
        setConcertSeriesId(newValue);
    };

    const getConcertName = (concert: IConcertDto) => {
        if (concert.location) {
            return `${concert.title} - ${concert.location.name}, ${concert.location.city} ${concert.date?.toFormat(
                'dd.MM.yyyy',
            )}`;
        }
        return `${concert.title} ${concert.date?.toFormat('dd.MM.yyyy')}`;
    };

    const renderConcerts = () => {
        const concertsOptions = [];
        concertsOptions.push(<option value={0}>Alle Konzerte</option>);
        for (const concert of concerts) {
            concertsOptions.push(<option value={concert.id}>{getConcertName(concert)}</option>);
        }
        return <>{concertsOptions}</>;
    };

    const getSeriesTitle = (series: IConcertSeriesDto) => {
        if (series.seriesStart) {
            const startMonth = series.seriesStart.toFormat('LLL');
            const startYear = series.seriesStart.toFormat('yyyy');
            if (series.seriesEnd) {
                const endMonth = series.seriesEnd.toFormat('LLL');
                const endYear = series.seriesEnd.toFormat('yyyy');
                if (startYear === endYear) {
                    if (startMonth === endMonth) {
                        return `${series.title} - ${startMonth} ${startYear}`;
                    }
                    return `${series.title} - (${startMonth}-${endMonth} ${startYear})`;
                }
                return `${series.title} (${startMonth} ${startYear} - ${endMonth} ${endYear})`;
            }

            return `${series.title} (${startMonth} ${startYear})`;
        }

        return series.title;
    };

    const renderConcertSeries = () => {
        const seriesOptions = [];
        let i = 0;
        seriesOptions.push(
            <option key={i} value={0}>
                Bitte wählen
            </option>,
        );
        for (const series of concertSeries) {
            i++;
            seriesOptions.push(
                <option key={i} value={series.id}>
                    <>{getSeriesTitle(series)}</>
                </option>,
            );
        }
        return <>{seriesOptions}</>;
    };

    return (
        <MainContent>
            {!isAllowed ? (
                <>
                    <div style={{ ...actionContainerStyle, textAlign: 'left', color: 'black' }}>Kein Zugriff.</div>
                </>
            ) : (
                <>
                    <div style={{ ...actionContainerStyle, textAlign: 'left' }}>
                        {isLoading && <LinearProgress />}
                        <Grid container>
                            <Grid item xs={12} md={4} style={{ padding: 5 }}>
                                <FormControl fullWidth>
                                    <InputLabel id={'concertSeries'} style={{ left: -15 }}>
                                        Konzertserie
                                    </InputLabel>
                                    <Select
                                        native
                                        value={concertSeriesId}
                                        onChange={changeConcertSeries}
                                        labelId="concertSeries"
                                        label="Konzertserie"
                                        inputProps={{
                                            name: 'concertSeries',
                                            id: 'concertSeries-native-simple',
                                        }}
                                        fullWidth
                                        variant="standard"
                                    >
                                        {renderConcertSeries()}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {concertSeriesId !== undefined && (
                                <>
                                    <Grid item xs={12} md={4} style={{ padding: 5 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="concert" style={{ left: -15 }}>
                                                Konzert
                                            </InputLabel>
                                            <Select
                                                native
                                                labelId="concert"
                                                value={concertId}
                                                label="Konzert"
                                                onChange={changeConcert}
                                                inputProps={{
                                                    name: 'concert',
                                                    id: 'concert-native-simple',
                                                }}
                                                fullWidth
                                                variant="standard"
                                            >
                                                {renderConcerts()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0} md={4}></Grid>
                                    <Grid item xs={12} md={4} style={{ padding: 5, marginTop: 20 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                onChange={(event) => setSearch(event.target.value as string)}
                                                value={search}
                                                id="search"
                                                onKeyDown={onEnter}
                                                type="search"
                                                variant="standard"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={8} style={{ padding: 5 }}>
                                        <FormControl style={{ color: 'black' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={!showAllOrdersWithPayed}
                                                        onChange={() =>
                                                            setShowAllOrdersWithPayed(!showAllOrdersWithPayed)
                                                        }
                                                        value="checkedA"
                                                    />
                                                }
                                                label="nur unbezahlte Bestellungen"
                                            />
                                        </FormControl>
                                        {/* <FormControl style={{ color: 'black' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={!showAllOrdersWithPayed}
                                                        onChange={() =>
                                                            setShowAllOrdersWithPayed(!showAllOrdersWithPayed)
                                                        }
                                                        value="checkedA"
                                                    />
                                                }
                                                label="stornierte Bestellungen anzeigen"
                                            />
                                        </FormControl> */}
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {concertSeriesId !== undefined && (
                            <>
                                {orders && orders.length > 0 && filteredOrders && (
                                    <div style={{ textAlign: 'left', margin: 10 }}>
                                        <Grid container>
                                            <Grid item xs={12} md={8}>
                                                <>
                                                    <div style={{ height: 1200, width: '95%' }}>
                                                        <div style={{ display: 'flex', height: '100%' }}>
                                                            <div style={{ flexGrow: 1 }}>
                                                                <DataGrid
                                                                    rows={filteredOrders}
                                                                    columns={columns}
                                                                    pageSize={40}
                                                                    rowsPerPageOptions={[40]}
                                                                    rowHeight={25}
                                                                    onSelectionModelChange={(selection) => {
                                                                        setSelectedOrder(
                                                                            orders?.find(
                                                                                (o) => o.id === Number(selection[0]),
                                                                            ) ?? null,
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                {selectedOrder ? (
                                                    <>
                                                        <div style={{ color: 'black' }}>
                                                            <Typography>Bestellung Nr: {selectedOrder?.id}</Typography>
                                                            <Card
                                                                style={{
                                                                    backgroundColor: '#EFEFEF',
                                                                    padding: 5,
                                                                    marginTop: 5,
                                                                    marginBottom: 10,
                                                                }}
                                                            >
                                                                <FormControl style={{ color: 'black' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selectedOrder.payed}
                                                                                onChange={async () => {
                                                                                    changePayed(selectedOrder);
                                                                                }}
                                                                                disabled={isLoading}
                                                                                value="checkedA"
                                                                            />
                                                                        }
                                                                        label="Bezahlt"
                                                                    />
                                                                </FormControl>
                                                                {/* <FormControl style={{ color: 'black' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selectedOrder.canceled}
                                                                                onChange={async () => {
                                                                                    changeCanceled(selectedOrder);
                                                                                }}
                                                                                disabled={isLoading}
                                                                                value="checkedA"
                                                                            />
                                                                        }
                                                                        label="Storniert"
                                                                    />
                                                                </FormControl> */}
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<DeleteIcon />}
                                                                    onClick={async () => {
                                                                        await service.deleteOrderAsync(
                                                                            selectedOrder.id,
                                                                        );
                                                                        setSelectedOrder(null);
                                                                        if (concertId) {
                                                                            await loadOrdersForConcert(
                                                                                concertSeriesId,
                                                                                concertId,
                                                                            );
                                                                        } else {
                                                                            await loadOrdersForSeries(concertSeriesId);
                                                                        }
                                                                    }}
                                                                >
                                                                    Bestellung löschen
                                                                </Button>
                                                            </Card>

                                                            <OrderInfo order={selectedOrder} />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </MainContent>
    );
};
