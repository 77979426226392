import { IContentItemDto, IConcertSeriesDto, ContentItemDtoType } from '../../clients/services';
import ConcertDates from './ConcertDates';
import ConcertInfo from './ConcertInfo';
import { Picture } from './Picture';
import { Audio } from './Audio';
import { BigTitle, SubTitle, TextParagraph, Title } from './TextParagraph';
import { TicketReservation } from './TicketReservation';

export const Paragraph = ({ p, series }: { p: IContentItemDto; series: IConcertSeriesDto }) => {
    switch (p.type) {
        case ContentItemDtoType.Picture:
            return p.url ? <Picture url={p.url} title={p.title} comment={p.text} /> : <></>;
        case ContentItemDtoType.DefaultPicture:
            return series.picture?.url ? (
                <Picture url={series.picture.url} title={series.picture.title} comment={series.title} />
            ) : (
                <></>
            );
        case ContentItemDtoType.Audio:
            return p.url ? <Audio {...p} /> : <></>;
        case ContentItemDtoType.ConcertInfo:
            return (
                <>
                    {!series.hideConcerts && (
                        <>
                            {series.concerts && series.concerts?.length > 1 ? (
                                <ConcertDates {...series} />
                            ) : (
                                series?.concerts &&
                                series?.concerts.filter((c) => !c.hide)[0] && (
                                    <ConcertInfo
                                        concert={series.concerts.filter((c) => !c.hide)[0]}
                                        concertSeries={series}
                                    />
                                )
                            )}
                        </>
                    )}
                </>
            );
        case ContentItemDtoType.TicketReservationLink:
            return <TicketReservation series={series} />;
        case ContentItemDtoType.Header1:
            return p.text ? <BigTitle text={p.text} series={series} /> : <></>;
        case ContentItemDtoType.Header2:
            return p.text ? <Title text={p.text} series={series} /> : <></>;
        case ContentItemDtoType.Header3:
            return p.text ? <SubTitle text={p.text} series={series} /> : <></>;
        default:
        case ContentItemDtoType.Text:
            return p.text ? <TextParagraph text={p.text} series={series} /> : <></>;
    }
};
